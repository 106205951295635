import React from "react";
import styled from "@emotion/styled";

import { GlobalNavCard } from "../card/globalNavCard";
import { GlobalNavBtn } from "../button/globalNavBtn";

export const GlobalNavCardWrapper = () => {
  return (
    <SWrapper>
      <GlobalNavCard icon="home" txt="ホーム" href="/" />
      <GlobalNavCard
        icon="format_list_bulleted"
        txt="カテゴリ一覧"
        href="/category"
      />
      <GlobalNavCard icon="headset_mic" txt="サポート" href="/support" />
      <GlobalNavCard icon="contact_support" txt="よくある質問" href="/faq" />
      <GlobalNavBtn />
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;
