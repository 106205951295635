import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";
import { CartBtn } from "../button/cartBtn";
import { UserBtn } from "../button/userBtn";

export const HeaderInformation = () => {
  return (
    <SHeaderInformation>
      <input type="text" placeholder="商品検索" />
      <CartBtn />
      <UserBtn />
    </SHeaderInformation>
  );
};

const SHeaderInformation = styled.div`
  display: flex;
  align-items: center;
  input {
    border: 1px solid ${Color.border};
    border-radius: 100vh;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    line-height: 1;
    margin-right: 2rem;
    outline: none;
    background-color: none;
    width: 8em;
    transition: all 0.2s;
    &:focus {
      width: 20em;
      border-color: ${Color.primary};
    }
  }
  button {
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;
