import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const GlobalNavBtn = () => {
  const onClickHandler = () => {
    const target = document.getElementById("globalNavListWrapper");
    target.classList.toggle("isOpen");
  };
  return (
    <SButton onClick={onClickHandler}>
      <span className="material-icons-outlined">drag_handle</span>
      <span>メニュー</span>
    </SButton>
  );
};

const SButton = styled.button`
  width: calc(100% / 5);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  background: none;
  border: none;
  span {
    color: ${Color.primary};
    line-height: 1;
    &:first-of-type {
      font-size: 2rem;
    }
    &:last-of-type {
      font-size: 0.6rem;
      margin-top: 0.5rem;
    }
  }
`;
