import React from "react";
import styled from "@emotion/styled";

import { GlobalNavCardWrapper } from "../wrapper/globalNavCardWrapper";
import { GlobalNavListWrapper } from "../wrapper/globalNavListWrapper";

export const GlobalNav = () => {
  return (
    <SNav>
      <GlobalNavCardWrapper />
      <GlobalNavListWrapper />
    </SNav>
  );
};

const SNav = styled.nav`
  display: none;
  box-shadow: 0px 0px 16px -6px #bfbfba;
  /* tb */
  @media screen and (max-width: 1023px) {
    position: sticky;
    bottom: 0;
    left: 0;
    display: block;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    z-index: 101;
  }
`;
