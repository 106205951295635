import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";
import { Link } from "gatsby";

export const GlobalNavCard = (props) => {
  return (
    <SCard>
      <Link to={props.href}>
        <span className="material-icons-outlined">{props.icon}</span>
        <span>{props.txt}</span>
      </Link>
    </SCard>
  );
};

const SCard = styled.div`
  width: calc(100% / 5);
  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    span {
      color: ${Color.primary};
      line-height: 1;
      &:first-of-type {
        font-size: 2rem;
      }
      &:last-of-type {
        font-size: 0.6rem;
        margin-top: 0.5rem;
      }
    }
  }
`;
