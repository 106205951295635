import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

import { GlobalNavCard } from "../card/globalNavCard";

export const GlobalNavListWrapper = () => {
  return (
    <SWrapper id="globalNavListWrapper">
      <GlobalNavCard icon="shopping_cart" txt="カート" href="/" />
      <GlobalNavCard icon="person_outline" txt="アカウント" href="/" />
      <GlobalNavCard icon="search" txt="商品検索" href="/" />
      <GlobalNavCard icon="share" txt="シェア" href="/" />
      <GlobalNavCard icon="ondemand_video" txt="YouTube" href="/" />
      <h1>MELON</h1>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  height: 0;
  transition: height 0.2s;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  h1 {
    width: 100%;
    margin-top: 40px;
    font-size: 40px;
    color: ${Color.primary};
    text-align: center;
    line-height: 1;
  }
  &.isOpen {
    height: 160px;
    padding: 0.5rem 0;
  }
`;
