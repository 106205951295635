import React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const UserBtn = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickHandler = () => setIsOpen(!isOpen);
  return (
    <>
      <SButton onClick={onClickHandler}>
        <span className="material-icons-outlined">person_outline</span>
      </SButton>
      <SWrapper data-open-state={isOpen}>
        <SHeading>
          <p>アカウント</p>
        </SHeading>
        <SInner>
          <Link to="/">デマンド太郎</Link>
          <Link to="/">マイページ</Link>
          <Link to="/">アカウント設定</Link>
          <Link to="/">ログアウト</Link>
        </SInner>
      </SWrapper>
    </>
  );
};

const SButton = styled.button`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 1.5rem;
    color: ${Color.dark};
    line-height: 1;
    transition: all 0.2s;
  }
  &:hover {
    span {
      color: ${Color.primary};
    }
  }
`;

const SWrapper = styled.div`
  &[data-open-state="false"] {
    display: none;
  }
  &[data-open-state="true"] {
    display: flex;
    flex-direction: column;
  }
  position: fixed;
  top: 64px;
  right: 32px;
  background-color: #fff;
  border: 1px solid ${Color.border};
  border-radius: 8px;
  padding: 1rem;
  z-index: 100;
`;

const SHeading = styled.div`
  padding-bottom: 1rem;
  p {
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }
`;

const SInner = styled.div`
  padding-top: 1rem;
  border-top: 1px solid ${Color.border};
  display: flex;
  flex-direction: column;
  a {
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    color: ${Color.dark};
    padding: 0.5rem 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      color: ${Color.primary};
    }
  }
`;
