import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const Logo = () => {
  return (
    <SLogo>
      <Link to="/">MELON</Link>
    </SLogo>
  );
};

const SLogo = styled.div`
  height: 64px;
  display: inline-flex;
  align-items: center;
  padding: 0 2rem;
  a {
    line-height: 1;
    color: ${Color.primary};
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
  }
`;
