export const SideNavList = [
  { href: "/", jpLabel: "ホーム", enLabel: "Home", icon: "home" },
  {
    href: "/category",
    jpLabel: "カテゴリ一覧",
    enLabel: "Category",
    icon: "format_list_bulleted",
  },
  {
    href: "/support",
    jpLabel: "サポート",
    enLabel: "Support",
    icon: "headset_mic",
  },
  {
    href: "/faq",
    jpLabel: "よくある質問",
    enLabel: "faq",
    icon: "contact_support",
  },
  {
    href: "/shoplist",
    jpLabel: "店舗一覧",
    enLabel: "Shop List",
    icon: "store",
  },
];
