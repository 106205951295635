import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";
import { Link } from "gatsby";

import { Logo } from "../logo/logo";
import { SideNavList } from "../../utils/sideNavList";

export const SideNav = () => {
  return (
    <SSideNav>
      <Logo />
      <SSideNavList>
        {SideNavList.map(({ href, jpLabel, icon }) => {
          return (
            <Link to={href} key={href}>
              <span>
                <i className="material-icons-outlined">{icon}</i>
                {jpLabel}
              </span>
            </Link>
          );
        })}
      </SSideNavList>
      <SSideNavFooter>
        <span className="material-icons-outlined">share</span>
        <span className="material-icons-outlined">ondemand_video</span>
        <span className="material-icons-outlined">headset_mic</span>
      </SSideNavFooter>
    </SSideNav>
  );
};

const SSideNav = styled.nav`
  grid-area: sideNav;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 240px;
  border-right: 1px solid ${Color.border};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* tb */
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const SSideNavList = styled.div`
  margin-top: 2rem;
  display: inline-flex;
  flex-direction: column;
  a {
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;
    line-height: 1;
    padding: 1rem 2rem;
    span {
      transition: all 0.2s;
      display: inline-flex;
      align-items: center;
      i {
        margin-right: 0.5rem;
        transition: all 0.2s;
      }
      &:first-of-type {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
    &:hover {
      span {
        i {
          color: ${Color.primary};
        }
        color: ${Color.primary};
      }
    }
  }
`;

const SSideNavFooter = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${Color.border};
  span {
    line-height: 1;
    font-size: 1rem;
    color: ${Color.primary};
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 100vh;
  }
`;
