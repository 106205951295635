import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const Footer = () => {
  return (
    <SFooter>
      <SFooterNav>
        <span>プライバシーポリシー</span>
        <span>お問い合わせ</span>
      </SFooterNav>
      <SFooterBar>
        <p>このサイトはデモサイトです。</p>
        <span>Demand Link Co.,Ltd.</span>
      </SFooterBar>
    </SFooter>
  );
};

const SFooter = styled.footer`
  grid-area: footer;
  padding: 1rem 2rem;
  border-top: 1px solid ${Color.border};
`;

const SFooterNav = styled.nav`
  display: flex;
  justify-content: center;
  span {
    font-size: 0.9rem;
    text-decoration: none;
    margin: 0 1rem;
    &:hover {
      text-decoration: underline;
      color: ${Color.primary};
    }
  }
`;

const SFooterBar = styled.div`
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  margin-top: 2rem;
  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
`;
