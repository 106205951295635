import React from "react";
import styled from "@emotion/styled";
import "../../styles/global.css";

import { Seo } from "../seo/Seo";
import { Header } from "../header/header";
import { SideNav } from "../nav/sideNav";
import { Footer } from "../footer/footer";
import { GlobalNav } from "../nav/globalNav";

export const DefaultLayout = (props) => {
  return (
    <>
      <Seo pageTitle={props.pageTitle} />
      <Header headerTitle={props.headerTitle} />
      <SideNav />
      <SMain>{props.children}</SMain>
      <Footer />
      <GlobalNav />
    </>
  );
};

const SMain = styled.main`
  grid-area: main;
  min-height: 100vh;
  padding: 1rem 2rem;
  animation-name: pageMove;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;
  @keyframes pageMove {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  section {
    &:first-of-type {
      padding-top: 0rem;
      border: none;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 1rem 0.5rem;
  }
`;
