import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

import { HeaderInformation } from "./headerInformation";

export const Header = (props) => {
  return (
    <SHeader>
      <SHeaderTitle>{props.headerTitle}</SHeaderTitle>
      <HeaderInformation />
    </SHeader>
  );
};

const SHeader = styled.header`
  grid-area: header;
  background-color: ${Color.light};
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 2rem;
  z-index: 100;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 0 0.5rem;
  }
`;

const SHeaderTitle = styled.h1`
  line-height: 1;
  font-family: "Titillium Web", sans-serif;
  color: ${Color.primary};
`;
